import { Outlet } from "react-router-dom"
import './LayoutSteps.css'
import { Children } from "react"
import Button from "../../bt-components/Buttons"
const LayoutSteps=(props)=>{

    return <>
        <div className='step-container w-100'>
            <div className="flex w-100">
                <h2 style={{marginBottom:20,minWidth: '10%',}}>{props.title}</h2>

                {props.subTitle && 
                <h2 style={{
                    marginBottom:20, 
                    textAlign: 'center',
                    width: '100%',
                    }}>{props.subTitle}</h2>}

                {props.titleT1 && <h2 style={{marginBottom:20,minWidth: '10%', textAlign: 'right'}}>{props.titleT1}</h2> }

            </div>
            
            {props.children}

            <div className="step-actions">
                
                
                {
                props.actionBack 
                ?<Button 
                title={props.actionBack.title  || 'Previous'} 
                onClick={props.actionBack.action} 
                sty={{backgroud:'red'}}
                />
                :''
                }

{
                props.actionBack 
                ?<Button 
                title={props.actionNext.title || 'Next'} 
                onClick={props.actionNext.action} 
                sty={{backgroud:'red'}}
                />
                :''
                }
            
                
            </div>

            <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        </div>
    </>

}

LayoutSteps.defaultProps = {
    title:'Title',
   }

export default LayoutSteps