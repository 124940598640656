import Input from "../../../commonComponent/input/Input";
import '../inner-forms.css'
import masterService from '../../../services/masterService'
import formsService from '../../../services/formsService'
import { useEffect, useState } from "react";
import Button from "../../../commonComponent/button/Button";
import { useDispatch, useSelector } from "react-redux";
import HeadingA from "../../components/HeadingA";
import { useNavigate, useParams } from "react-router-dom";
import Alert from "../../../commonComponent/Alerts";
import MaterialContainer from "../../../commonComponent/materialContainer/MaterialContainer";
import Checkbox from "../../../commonComponent/checkboxInput/Checkbox";
import Dropdown from "../../../commonComponent/dropdown/Dropdown";
import MaterialInput from "../../../commonComponent/input/MaterialInput";
import LayoutSteps from "../../LayoutSteps";
import InputAmount from "../../../commonComponent/input/InputAmount";



const Form_UI_a=(props)=> {

    const titleStyle = {
        width : '100%',
        color: 'var(--sec-color)',
        fontSize: '1.1em',
        fontWeight: '600',
    }

    const subTitleStyle = {
        width: '100%',
    }

    const divResponsive = {
        flexDirection: window.innerWidth < 800 ? "column" : "row",
        gap: window.innerWidth < 800 ? "0" : "1",
    }
    const inputStyle = {
        minWidth: window.innerWidth < 800 ? '100%' :'20%',
        width: '20%',
    }

    const boxStyle = {
        background: 'black',
        color: 'white',
        padding: '0.2em 0.5em',
    }

    const dropDownStyle = {
        minWidth: window.innerWidth < 500 ? '100%' :'20%',
        width: '20%',
        
    }

    function toFormatter(number) {
      // const formatter = new Intl.NumberFormat("sv-SE", {
        const formatter = new Intl.NumberFormat("en-US", {
        style: "decimal",
        // currency: "SEK"
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

      return formatter.format(number);
    }

    return (
        <div className="form-input-ui-div flex-column w-100">
            {props?.title ? <div style={titleStyle}><p>{props.title}</p></div> : ""}
            <div style={divResponsive} className="w-100 flex flex-between gap-1">
                <p style={subTitleStyle} className="subtitle">{props.subTitle}</p>
                {props.div_1 ? 
                <div className="w-20 flex-centered">
                    <p>{props.div_1}</p>
                </div>
                :""
                }
                {props.box0 && <p style={boxStyle}>{props.box0}</p>}
                {props.input === 'yes' ? 
                    <div  className="flex"  style={inputStyle}>
                        <InputAmount 
                          disabled 
                          input={props.input} 
                          type={props.type} 
                          name={props.name} 
                          value={props?.value}
                          // value={props.value != '' && props.value != null 
                          //   ? 
                          //   toFormatter(parseFloat(props?.value))
                          //   : 
                          //   props?.value == '0' 
                          //   ?
                          //   toFormatter(parseFloat('0')) :
                          //   props?.value
                          // }  
                          
                          hint='' 
                          handleChange={(name, value) => { 
                            props.handleChange(name, value)}}/>
                    </div>
                : props.input && (<p style={{textAlign: 'end', width: '100%'}}>{props.input}</p>) 
                }
                {props.div_2 ? 
                    <div style={{minWidth: '5%'}} className=" flex-centered">
                        <p>{props.div_2}</p>
                    </div>
                :""
                }
                {props.box && <p style={boxStyle}>{props.box}</p>}

                {props.div_3 ? 
                <div style={{minWidth: '2%'}} className=" flex-centered">
                    <p>{props.div_3}</p>
                </div>
                :""
                }
                <div  className="flex"  style={inputStyle}>
                {props.input1 === 'yes' ? 
                
                    <InputAmount 
                      disabled 
                      input={props.input1} 
                      type={props.type} 
                      name={props.name_a} 
                      value={props.value1 != '' && props.value1 != null ? parseFloat(props.value1) : props.value1 == "0" ? parseFloat('0') : props?.value1} 
                      // value={props.value1 != '' && props.value1 != null 
                      //   ? 
                      //   toFormatter(parseFloat(props?.value1))
                      //   : 
                      //   props?.value1 == '0' 
                      //   ?
                      //   toFormatter(parseFloat('0')) :
                      //   props?.value1
                      // } 
                      hint='' 
                      handleChange={(name, value) => { props.handleChange(name, value)}}/>
                
                :<p style={{textAlign: 'end', width: '100%'}}>{props.input1}</p>
                }
                </div>
                {props.div_3_a0 ? 
                <div style={{minWidth: '2%'}} className=" flex-centered">
                    <p>{props.div_3_a0}</p>
                </div>
                :""
                }
                {props.box2 && <p style={boxStyle}>{props.box2}</p>}
                {props.div_3_a ? 
                <div style={{minWidth: '2%'}} className=" flex-centered">
                    <p>{props.div_3_a}</p>
                </div>
                :""
                }
                
                <div  className="flex"  style={inputStyle}>
                    {props.input2 === 'yes' ?
                        <InputAmount  
                          disabled 
                          input={props.input2} 
                          type={props.type} 
                          name={props.name_b} 
                          // value={props.value2 != '' && props.value2 != null 
                          //   ? 
                          //   toFormatter(parseFloat(props?.value2))
                          //   : 
                          //   props?.value2 == '0' 
                          //   ?
                          //   toFormatter(parseFloat('0')) :
                          //   props?.value2
                          // } 
                          value={props.value2}
                          hint='' 
                          handleChange={(name, value) => { 
                            props.handleChange(name, value)}}/>
                        :<p style={{textAlign: 'end', width: '100%'}}>{props.input2}</p>
                    }
                </div>
                {props.dropdown && (
                    <div style={dropDownStyle} className="flex ">
                        <Dropdown name={props.drop_name} value={props?.drop_value} hint='' lable={props.drop_lable} handleChange={(name, value) => { props.handleChange(name, value)}}/>
                    </div>
                )}
                {props.div_4 ? 
                <div style={{minWidth: '2%'}} className=" flex-centered">
                    <p>{props.div_4}</p>
                </div>
                :""
                }
            </div>
            <hr />
        </div>
    )

}
Form_UI_a.defaultProps = {
    type: 'text',
}


const Form_UI_input=(props)=> {

  const titleStyle = {
      width : '100%',
      color: 'var(--sec-color)',
      fontSize: '1.1em',
      fontWeight: '600',
  }

  const subTitleStyle = {
      width: '100%',
  }

  const divResponsive = {
      flexDirection: window.innerWidth < 800 ? "column" : "row",
      gap: window.innerWidth < 800 ? "0" : "1",
  }
  const inputStyle = {
      minWidth: window.innerWidth < 800 ? '100%' :'20%',
      width: '20%',
  }

  const boxStyle = {
      background: 'black',
      color: 'white',
      padding: '0.2em 0.5em',
  }

  const dropDownStyle = {
      minWidth: window.innerWidth < 500 ? '100%' :'20%',
      width: '20%',
      
  }

  function toFormatter(number) {
    // const formatter = new Intl.NumberFormat("sv-SE", {
      const formatter = new Intl.NumberFormat("en-US", {
      style: "decimal",
      // currency: "SEK"
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return formatter.format(number);
  }

  return (
      <div className="form-input-ui-div flex-column w-100">
          {props?.title ? <div style={titleStyle}><p>{props.title}</p></div> : ""}
          <div style={divResponsive} className="w-100 flex flex-between gap-1">
              <p style={subTitleStyle} className="subtitle">{props.subTitle}</p>
              {props.div_1 ? 
              <div className="w-20 flex-centered">
                  <p>{props.div_1}</p>
              </div>
              :""
              }
              {props.box0 && <p style={boxStyle}>{props.box0}</p>}
              {props.input === 'yes' ? 
                  <div  className="flex"  style={inputStyle}>
                      <Input 
                        disabled 
                        input={props.input} 
                        type={props.type} 
                        name={props.name} 
                        value={props?.value}
                        // value={props.value != '' && props.value != null 
                        //   ? 
                        //   toFormatter(parseFloat(props?.value))
                        //   : 
                        //   props?.value == '0' 
                        //   ?
                        //   toFormatter(parseFloat('0')) :
                        //   props?.value
                        // }  
                        
                        hint='' 
                        handleChange={(name, value) => { 
                          props.handleChange(name, value)}}/>
                  </div>
              : props.input && (<p style={{textAlign: 'end', width: '100%'}}>{props.input}</p>) 
              }
              {props.div_2 ? 
                  <div style={{minWidth: '5%'}} className=" flex-centered">
                      <p>{props.div_2}</p>
                  </div>
              :""
              }
              {props.box && <p style={boxStyle}>{props.box}</p>}

              {props.div_3 ? 
              <div style={{minWidth: '2%'}} className=" flex-centered">
                  <p>{props.div_3}</p>
              </div>
              :""
              }
              <div  className="flex"  style={inputStyle}>
              {props.input1 === 'yes' ? 
              
                  <Input
                    disabled 
                    input={props.input1} 
                    type={props.type} 
                    name={props.name_a} 
                    value={props.value1 != '' && props.value1 != null ? parseFloat(props.value1) : props.value1 == "0" ? parseFloat('0') : props?.value1} 
                    // value={props.value1 != '' && props.value1 != null 
                    //   ? 
                    //   toFormatter(parseFloat(props?.value1))
                    //   : 
                    //   props?.value1 == '0' 
                    //   ?
                    //   toFormatter(parseFloat('0')) :
                    //   props?.value1
                    // } 
                    hint='' 
                    handleChange={(name, value) => { props.handleChange(name, value)}}/>
              
              :<p style={{textAlign: 'end', width: '100%'}}>{props.input1}</p>
              }
              </div>
              {props.div_3_a0 ? 
              <div style={{minWidth: '2%'}} className=" flex-centered">
                  <p>{props.div_3_a0}</p>
              </div>
              :""
              }
              {props.box2 && <p style={boxStyle}>{props.box2}</p>}
              {props.div_3_a ? 
              <div style={{minWidth: '2%'}} className=" flex-centered">
                  <p>{props.div_3_a}</p>
              </div>
              :""
              }
              
              <div  className="flex"  style={inputStyle}>
                  {props.input2 === 'yes' ?
                      <Input  
                        style={props.style}
                        disabled 
                        input={props.input2} 
                        type={props.type} 
                        name={props.name_b} 
                        // value={props.value2 != '' && props.value2 != null 
                        //   ? 
                        //   toFormatter(parseFloat(props?.value2))
                        //   : 
                        //   props?.value2 == '0' 
                        //   ?
                        //   toFormatter(parseFloat('0')) :
                        //   props?.value2
                        // } 
                        value={props.value2}
                        hint='' 
                        handleChange={(name, value) => { 
                          props.handleChange(name, value)}}/>
                      :<p style={{textAlign: 'end', width: '100%'}}>{props.input2}</p>
                  }
              </div>
              {props.dropdown && (
                  <div style={dropDownStyle} className="flex ">
                      <Dropdown name={props.drop_name} value={props?.drop_value} hint='' lable={props.drop_lable} handleChange={(name, value) => { props.handleChange(name, value)}}/>
                  </div>
              )}
              {props.div_4 ? 
              <div style={{minWidth: '2%'}} className=" flex-centered">
                  <p>{props.div_4}</p>
              </div>
              :""
              }
          </div>
          <hr />
      </div>
  )

}
Form_UI_input.defaultProps = {
  type: 'text',
}




const Form_UI_b=(props)=> {

    const titleStyle = {
        width : '100%',
        color: 'var(--sec-color)',
        fontSize: '1.1em',
        fontWeight: '600',
    }

    const subTitleStyle = {
        width: '100%',
    }

    const divResponsive = {
        flexDirection: window.innerWidth < 500 ? "column" : "row",
        gap: window.innerWidth < 500 ? "1" : "1",
    }

    const checkBoxStyle = {
        minWidth: window.innerWidth < 500 ? '100%' :'20%',
        width: '20%',
        justifyContent: window.innerWidth < 500 ? 'flex-start' : 'flex-end',

    }

    const boxStyle = {
        background: 'black',
        color: 'white',
        padding: '0.2em 0.5em',
    }

    const inputResponsive = {
        minWidth: '10%',
        width: '100%',
    }

    const dropDownStyle = {
        minWidth: window.innerWidth < 500 ? '100%' :'20%',
        width: '20%',
        
    }

    return (
        <div className="form-input-ui-div flex flex-column w-100 gap-1">
            {props?.title ? <div style={titleStyle}><p>{props.title}</p></div> : ""}
            <div style={divResponsive} className="w-100 flex flex-between gap-1">
                <p style={subTitleStyle} className="subtitle">{props.subTitle}</p>
                {props.div_1 ? 
                    <div style={{minWidth: '2%'}} className=" flex-centered">
                        <p>{props.div_1}</p>
                    </div>
                :""
                }
                {props.input1 == 'yes' ?
                    <div style={inputResponsive}>
                        <InputAmount input={props.input1} disabled type={props.type} name={props.Iname1} value={props?.Ivalue1} hint='' handleChange={(name, value) => { props.handleChange(name, value)}}/>
                    </div>
                    :""
                }
                {props.div_2 ? 
                    <div style={{minWidth: '2%'}} className=" flex-centered">
                        <p>{props.div_2}</p>
                    </div>
                :""
                }
                {props.input2 == 'yes' ?
                    <div style={inputResponsive}>
                        <InputAmount input={props.input2} disabled type={props.type} name={props.Iname2} value={props?.Ivalue2} hint='' handleChange={(name, value) => { props.handleChange(name, value)}}/>
                    </div>
                    :""
                }
                {props.div_3 ? 
                    <div style={{minWidth: '2%'}} className=" flex-centered">
                        <p>{props.div_3}</p>
                    </div>
                :""
                }
                {props.input3 == 'yes' ?
                    <div style={inputResponsive}>
                        <InputAmount input={props.input3} disabled type={props.type} name={props.Iname3} value={props?.Ivalue3} hint='' handleChange={(name, value) => { props.handleChange(name, value)}}/>
                    </div>
                    :""
                }
                {props.div_4 ? 
                    <div style={{minWidth: '2%'}} className=" flex-centered">
                        <p>{props.div_4}</p>
                    </div>
                :""
                }
                {props.input4 == 'yes' ?
                    <div style={inputResponsive}>
                        <InputAmount input={props.input4} disabled type={props.type} name={props.Iname4} value={props?.Ivalue4} hint='' handleChange={(name, value) => { props.handleChange(name, value)}}/>
                    </div>
                    :""
                }
                {props.div_5 ? 
                    <div style={{minWidth: '2%'}} className=" flex-centered">
                        <p>{props.div_5}</p>
                    </div>
                :""
                }
                {props.input5 == 'yes' ?
                    <div style={inputResponsive}>
                        <InputAmount input={props.input5} disabled type={props.type} name={props.Iname5} value={props?.Ivalue5} hint='' handleChange={(name, value) => { props.handleChange(name, value)}}/>
                    </div>
                    :""
                }
                {props.div_6 ? 
                    <div style={{minWidth: '2%'}} className=" flex-centered">
                        <p>{props.div_6}</p>
                    </div>
                :""
                }
            </div>
            <hr />
        </div>
    )
    
}



const Form_UI_70=(props)=> {

  const titleStyle = {
      width : '100%',
      color: 'var(--sec-color)',
      fontSize: '1.1em',
      fontWeight: '600',
  }

  const subTitleStyle = {
      width: '100%',
  }

  const divResponsive = {
      flexDirection: window.innerWidth < 500 ? "column" : "row",
      gap: window.innerWidth < 500 ? "1" : "1",
  }

  const checkBoxStyle = {
      minWidth: window.innerWidth < 500 ? '100%' :'20%',
      width: '20%',
      justifyContent: window.innerWidth < 500 ? 'flex-start' : 'flex-end',

  }

  const boxStyle = {
      background: 'black',
      color: 'white',
      padding: '0.2em 0.5em',
  }

  const inputResponsive = {
      minWidth: '10%',
      width: '100%',
  }

  const dropDownStyle = {
      minWidth: window.innerWidth < 500 ? '100%' :'20%',
      width: '20%',
      
  }

  return (
      <div className="form-input-ui-div flex flex-column w-100 gap-1">
          {props?.title ? <div style={titleStyle}><p>{props.title}</p></div> : ""}
          <div style={divResponsive} className="w-100 flex flex-between gap-1">
              <p style={subTitleStyle} className="subtitle">{props.subTitle}</p>
              {props.div_1 ? 
                  <div style={{minWidth: '2%'}} className=" flex-centered">
                      <p>{props.div_1}</p>
                  </div>
              :""
              }
              {props.input1 == 'yes' ?
                  <div style={inputResponsive}>
                      <Input input={props.input1} disabled type={props.type} name={props.Iname1} value={props?.Ivalue1} hint='' handleChange={(name, value) => { props.handleChange(name, value)}}/>
                  </div>
                  :""
              }
              {props.div_2 ? 
                  <div style={{minWidth: '2%'}} className=" flex-centered">
                      <p>{props.div_2}</p>
                  </div>
              :""
              }
              {props.input2 == 'yes' ?
                  <div style={inputResponsive}>
                      <Input input={props.input2} disabled type={props.type} name={props.Iname2} value={props?.Ivalue2} hint='' handleChange={(name, value) => { props.handleChange(name, value)}}/>
                  </div>
                  :""
              }
              {props.div_3 ? 
                  <div style={{minWidth: '2%'}} className=" flex-centered">
                      <p>{props.div_3}</p>
                  </div>
              :""
              }
              {props.input3 == 'yes' ?
                  <div style={inputResponsive}>
                      <Input input={props.input3} disabled type={props.type} name={props.Iname3} value={props?.Ivalue3} hint='' handleChange={(name, value) => { props.handleChange(name, value)}}/>
                  </div>
                  :""
              }
              {props.div_4 ? 
                  <div style={{minWidth: '2%'}} className=" flex-centered">
                      <p>{props.div_4}</p>
                  </div>
              :""
              }
              {props.input4 == 'yes' ?
                  <div style={inputResponsive}>
                      <Input input={props.input4} disabled type={props.type} name={props.Iname4} value={props?.Ivalue4} hint='' handleChange={(name, value) => { props.handleChange(name, value)}}/>
                  </div>
                  :""
              }
              {props.div_5 ? 
                  <div style={{minWidth: '2%'}} className=" flex-centered">
                      <p>{props.div_5}</p>
                  </div>
              :""
              }
              {props.input5 == 'yes' ?
                  <div style={inputResponsive}>
                      <Input input={props.input5} disabled type={props.type} name={props.Iname5} value={props?.Ivalue5} hint='' handleChange={(name, value) => { props.handleChange(name, value)}}/>
                  </div>
                  :""
              }
              {props.div_6 ? 
                  <div style={{minWidth: '2%'}} className=" flex-centered">
                      <p>{props.div_6}</p>
                  </div>
              :""
              }
          </div>
          <hr />
      </div>
  )
  
}
const Form_UI_c=(props)=> {

    const titleStyle = {
        width : '100%',
        color: 'var(--sec-color)',
        fontSize: '1.1em',
        fontWeight: '600',
    }

    const subTitleStyle = {
        width: '100%',
    }

    const divResponsive = {
        flexDirection: window.innerWidth < 800 ? "column" : "row",
        gap: window.innerWidth < 800 ? "1" : "1",
    }

    const checkBoxStyle = {
        minWidth: window.innerWidth < 800 ? '100%' :'20%',
        maxWidth: '20%',
    }

    const boxStyle = {
      background: 'black',
      color: 'white',
      padding: '0.2em 0.5em',
    }

    const inputStyle = {
        minWidth: window.innerWidth < 800 ? '100%' :'20%',
        width: '20%',
    }

    return (
        <div className="form-input-ui-div flex flex-column w-100 gap-1">
            {props?.title ? <div style={titleStyle}><p>{props.title}</p></div> : ""}
            <div style={divResponsive} className="w-100 flex flex-between gap-1">
                <p style={subTitleStyle} className="subtitle">{props.subTitle}</p>
                {props.input == 'yes' ? 
                <div  className="flex-centered flex-column"  style={inputStyle}>
                    <Input input={props.input} type={props.type} name={props.name_a} value={props?.value1} hint='' handleChange={(name, value) => { props.handleChange(name, value)}}/>
                </div>
                :""
                }
                {props.input1 == 'yes' ? 
                <div  className="flex-centered flex-column"  style={inputStyle}>
                    <p>{props.txt1}</p>
                    <Input input={props.input1} type={props.type} name={props.name_a} value={props?.value1} hint='' handleChange={(name, value) => { props.handleChange(name, value)}}/>
                </div>
                :""
                }
                {props.input2 == 'yes' ? 
                <div  className="flex-centered flex-column"  style={inputStyle}>
                    <p>{props.txt2}</p>
                    <Input input={props.input2} type={props.type} name={props.name_a} value={props?.value1} hint='' handleChange={(name, value) => { props.handleChange(name, value)}}/>
                </div>
                :""
                }
                {props.box && <div className="flex-centered"><p style={boxStyle}>{props.box}</p></div>}
                
                <div style={checkBoxStyle} className="flex-centered flex-column gap-2">
                    <p>{props.txt3}</p>
                    <div className="flex flex-centered gap-2">
                        <Checkbox disabled name={props.name} value={props?.value_1} hint='' lable={props.lable_1} handleChange={(name, value) => { props.handleChange(name, value)}}/>
                        <Checkbox disabled name={props.name} value={props?.value_2} hint='' lable={props.lable_2} handleChange={(name, value) => { props.handleChange(name, value)}}/>
                    </div>
                    
                </div>
                
            </div>
            <hr />
        </div>
    )
    
}

const Form_UI_d=(props)=> {

  const titleStyle = {
      width : '50%',
      color: 'var(--sec-color)',
      fontSize: '1.1em',
      fontWeight: '600',
  }

  const subTitleStyle = {
      width: '50%',
  }

  const divResponsive = {
      flexDirection: window.innerWidth < 800 ? "column" : "row",
      gap: window.innerWidth < 800 ? "0" : "1",
  }
  const inputStyle = {
      minWidth: window.innerWidth < 800 ? '100%' :'50%',
      width: '50%',
  }

  const boxStyle = {
      background: 'black',
      color: 'white',
      padding: '0.2em 0.5em',
  }

  const dropDownStyle = {
      minWidth: window.innerWidth < 500 ? '100%' :'20%',
      width: '20%',
      
  }

  function toFormatter(number) {
    // const formatter = new Intl.NumberFormat("sv-SE", {
      const formatter = new Intl.NumberFormat("en-US", {
      style: "decimal",
      // currency: "SEK"
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return formatter.format(number);
  }

  return (
      <div className="form-input-ui-div flex-column w-100">
          {props?.title ? <div style={titleStyle}><p>{props.title}</p></div> : ""}
          <div style={divResponsive} className="w-100 flex flex-between gap-1">
              <p style={subTitleStyle} className="subtitle">{props.subTitle}</p>
              {props.box2 && <p style={boxStyle}>{props.box2}</p>}
              {props.div_3_a ? 
              <div style={{minWidth: '2%'}} className=" flex-centered">
                  <p>{props.div_3_a}</p>
              </div>
              :""
              }
              
              <div  className="flex w-100"  style={inputStyle}>
                  {props.input2 === 'yes' ?
                      <Input 
                        disabled 
                        input={props.input2}  
                        type={props.type} 
                        name={props.name_b}
                        // value={props?.value2} 
                          value={props.value2 != '' && props.value2 != null ? toFormatter(parseFloat(props?.value2)): props?.value2} 

                        hint='' 
                        handleChange={(name, value) => { props.handleChange(name, value)}}/>
                      :<p style={{textAlign: 'end', width: '100%'}}>{props.input2}</p>
                  }
              </div>
              {props.div_4 ? 
              <div style={{minWidth: '2%'}} className=" flex-centered">
                  <p>{props.div_4}</p>
              </div>
              :""
              }
          </div>
          <hr />
      </div>
  )

}


const CheckboxMaritalStatus = (props) => {
  return(
    <div className="flex flex-column gap-1">
      {/* {props.value} */}
      {props?.checkboxes.map((item, i)=>{
        return(
          <div className="flex gap-1" key={i}>
            {item.title && <p style={{width: '80%'}}>{item.title}</p>}

            <div className="flex-centered"><p style={{minWidth: '1em'}}>{item.count ? i+1 : ''} </p></div>
            <div className="flex">
              <Checkbox 
                name={item.name}  
                value={item?.id == props?.value} 
                hint='' 
                id={item?.id}
                lable={item?.lable}
                disabled
                
              />
            </div>
          </div>
          
        )
      })}
    </div>
  )
}

const CheckboxForm = (props) => {
  return(
    <div className="flex flex-column gap-1">
      {/* {props.value} */}
      {props?.checkboxes.map((item, i)=>{
        return(
          <div className="flex gap-1" key={i}>
            {item.title && <p style={{width: '80%'}}>{item.title}</p>}

            <div className="flex-centered"><p style={{minWidth: '1em'}}>{item.count ? i+1 : ''} </p></div>
            <div className="flex">
              <Checkbox 
                name={item.name}  
                value={props?.value} 
                hint='' 
                id={item?.id}
                lable={item?.lable}
                disabled
                
              />
            </div>
          </div>
          
        )
      })}
    </div>
  )
}

const F_T5006_AddNew=()=>{

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [errors,setErrors]=useState({});
    const [submitError,setSubmitError]=useState('');
    const navigate=useNavigate();
    const {action}=useParams();

        const [payload,setPayload]=useState({})

        const [taxPayerPayload,setTaxPayerPayload]=useState({})
        const [spousePayload,setSpousePayload]=useState({})
        const [statusPayload,setStatusPayload]=useState({})


        const [inputValue, setInputValue] = useState();
        
        const auth=useSelector((state)=>state.auth);


        useEffect(() => {
          formsService
            .getDetails({ form: "PersonalinfoTaxpayer" })
            .then(async (res) => {
              setTaxPayerPayload(res?.data?.response?.form_data);
            })
            .catch((error) => {
              console.log("errors ", error);
            });
        }, []);


        useEffect(() => {
          formsService
            .getDetails({ form: "PersonalinfoSpouse" })
            .then(async (res) => {
              setSpousePayload(res?.data?.response?.form_data);
            })
            .catch((error) => {
              console.log("errors ", error);
            });
        }, []);


        useEffect(() => {
          formsService
            .getDetails({ form: "PersonalinfoStatus" })
            .then(async (res) => {
              setStatusPayload(res?.data?.response?.form_data);
            })
            .catch((error) => {
              console.log("errors ", error);
            });
        }, []);

        useEffect(() => {
            // if(action!==undefined){
                formsService.getPreview({form: 'F_T5006'})
            .then( async(res) => {
                console.log('resppp',res)
                
                setPayload(res?.data?.response?.details);
                
            })
            .catch((error) => {
                console.log('errors ',error)
            });
            // }
            
        }, [])


        useEffect(() => {
          formsService
            .getDetails({ form: "PersonalinfoTaxpayer" })
            .then(async (res) => {
              setTaxPayerPayload(res?.data?.response?.form_data);
            })
            .catch((error) => {
              console.log("errors ", error);
            });
        }, []);

        const handleChange = (name, value) => {
          setSubmitError('')
          setPayload({
              ...payload,
              [name]: value,
          })
          setErrors({
              ...errors,
              [name]: ''
          })
      }
        console.log('payload is',payload)


    // const  handleSubmit=(e)=>{
    //     e.preventDefault();
        
    //     if(action===undefined){
    //         formsService.addNew({form: 'F_T5006', form_data: payload})
    //     .then( (res) => {
    //         //---alert after successful submission
    //         Alert.success(res?.data?.message)
    //         //---redirect to details page of current submissions
    //         navigate('/user/return-YR22/forms/F_T5006/view/'+res?.data?.response?.new_id)
    //         // setEmployeeCodesList(res.data.response.list);
    //     })
    //     .catch((error) => {
    //         console.log('errors ',error?.response?.data)
    //         setErrors(error?.response?.data?.errors)
    //         setSubmitError(error?.response?.data?.message)
    //     });
    //     }else{
    //         formsService.update({form: 'F_T5006', form_data: payload})
    //     .then( (res) => {
    //         //---alert after successful submission
    //         Alert.success(res?.data?.message)
    //         //---redirect to details page of current submissions
    //         navigate('/user/return-YR22/forms/F_T5006/view')
    //         // setEmployeeCodesList(res.data.response.list);
    //     })
    //     .catch((error) => {
    //         console.log('errors ',error?.response?.data)
    //         setErrors(error?.response?.data?.errors)
    //         setSubmitError(error?.response?.data?.message)
    //     });
    //     }
        
    // }
    const [provincesList, setProvincesList] = useState([]);
    const [residencyList, setResidencyList] = useState([]);

    useEffect(() => {

      //---fetch Provinces
      masterService.getProvinces()
              .then((res) => {
                  setProvincesList(res.data.response.list);
              })
              .catch((error) => {
                  console.log(error.message)
              });
      masterService.getResidency()
          .then((res) => {
              setResidencyList(res.data.response.list);
          })
          .catch((error) => {
              console.log(error.message)
          });
    }, [])
  


    return  <LayoutSteps
    title=" "
    titleT1="T1 2022"
    subTitle="Income Tax and Benefit Return"
    actionBack={{action:()=>navigate("/user/return-YR22/step/qna")}}
    actionNext={{action:()=>navigate("/user/return-YR22/step/to-cra"),title:'Submit To CRA'}}
  >
            <div className='form-container'>
                <div className='form-inputs'>
                    <form method='post' >
                        <div className='form-group'>
                        {/* <HeadingA title="T1 2022" subtitle="Income Tax and Benefit Return" /> */}
                            
                            <div className='form-group-div flex-column'>
                                <p>
                                  <b>If this return is for a deceased person, enter their information on this page.</b>
                                  <br/>
                                  <br/>
                                  <b>Attach </b>
                                  to your paper return only the documents that are requested to support your deduction, claim, or expense. Keep all other
                                  documents in case the Canada Revenue Agency (CRA) asks to see them later. See the guide for more information about
                                  supporting documents.
                                </p>
                                <br/>
                                <MaterialContainer heading="Step 1 – Identification and other information">
                                  <p><b></b></p>

                                  <MaterialContainer heading="Identification">
                                    <div className="identificationDiv">
                                      <div className="flex gap-1">
                                        <Input hint="" lable="First Name" name="fname" disabled value={taxPayerPayload?.fname}/>
                                        <Input hint="" lable="Last Name" name="last_name" disabled value={taxPayerPayload?.lname}/>
                                        <Input hint="" lable="Mailing Address" disabled name="mailing_address" 
                                          value={
                                            taxPayerPayload?.street ? taxPayerPayload?.street_number + (", ") + taxPayerPayload?.street : taxPayerPayload?.street_number
                                            
                                            }/>
                                        <Input hint="" lable="PO Box" disabled name="po_box"/>
                                      </div>
                                      <div className="flex gap-1">
                                        <Input hint="" lable="RR" name="rr" disabled value={taxPayerPayload?.rr}/>
                                        <Input hint="" lable="City" name="city" disabled value={taxPayerPayload?.city}/>
                                        <Input hint="" lable="Postal code" name="postalcode" disabled value={taxPayerPayload?.postalcode}/>
                                        <Input hint="" lable="Email address" name="email" disabled value={taxPayerPayload?.email}/>
                                      </div>
                                      <div className="flex gap-1">
                                        <Dropdown 
                                          disabled 
                                          lable="Prov./Terr." 
                                          name="province_of_return"  
                                          
                                          selected={statusPayload?.province_of_return}
                                          visible={statusPayload.province_of_return == "0" || statusPayload.province_of_return == null ? true : false}
                                          options={{list: provincesList, name:'name',value:'id'}}
                                          handleChange={(name, value) => { handleChange(name, value)}}
                                          />
                                      </div>
                                    </div>
                                    <p>By providing an email address, you are registering to receive
                                        email notifications from the CRA and agree to the Terms of
                                        use in Step 1 of the guide.</p>
                                  {/* </MaterialContainer>
                                  <MaterialContainer> */}
                                    <MaterialContainer>
                                      <CheckboxForm 
                                        checkboxes={[
                                          {title: 'Your language of correspondence:', lable:'English', name:'english', value: taxPayerPayload?.english, },
                                          {title: 'Votre langue de correspondance :', lable:'Français', name:'français', value: taxPayerPayload?.français},
                                        ]}
                                        handleChange={(name, value) => { handleChange(name, value)}}
                                      />
                                    </MaterialContainer>
                                    
                                    
                                    <div className="flex gap-1">
                                      
                                        <div className="w-100">
                                        <MaterialContainer>
                                          <div className="flex flex-column gap-1">
                                            <Input hint="" disabled lable="Social insurance number (SIN)" name="sin" value={taxPayerPayload?.sin}/>
                                            <Input hint="" disabled lable="Date of birth (Year Month Day)" name="dob" value={taxPayerPayload?.dob}/>
                                            <Input hint="" disabled lable="If this return is for a deceased person, enter the date of death (Year Month Day)" name="deceased_person"/>
                                          </div>
                                        </MaterialContainer>
                                        </div>
                                        
                                        

                                        <div className="w-100">
                                        <MaterialContainer>
                                          <p>Marital Status on December 31, 2022:</p>
                                          <br/>
                                          <CheckboxMaritalStatus 
                                            value={taxPayerPayload?.maritalStatus}
                                            checkboxes={[
                                              {lable: 'Married', name:'married', id:'1' , count: true},
                                              {lable: 'Living common-law', name:'common_law', id:'2' , count: true},
                                              {lable: 'Widowed', name:'widowed', id:'3', count: true},
                                              {lable: 'Divorced', name:'divorced', id:'4', count: true},
                                              {lable: 'Seperated', name:'seperated', id:'5', count: true},
                                              {lable: 'Single', name:'single', id:'6', count: true},
                                            
                                            ]}
                                            
                                            handleChange={(name, value) => { handleChange(name, value)}}
                                          />
                                          {/* {console.log("onvvjndojvnonvodnvndrivndfk", taxPayerPayload?.maritalStatus)} */}

                                          </MaterialContainer>
                                        </div>
                                        
                                      </div>
                                    
                                  </MaterialContainer>
                                  {/* <br/> */}
                                  {/* <p><b></b></p> */}
                                  <MaterialContainer heading="Residence information">
                                              
                                    <div className="flex gap-1">
                                      <MaterialContainer>
                                        <div className="flex flex-column gap-1">
                                          <Dropdown 
                                            disabled 
                                            lable="Your province or territory of residence on December 31, 2022:" 
                                            name="province_of_return"
                                            // box="" 
                                            selected={statusPayload?.province_of_return}
                                            visible={statusPayload.province_of_return == "0" || statusPayload.province_of_return == null ? true : false}
                                            options={{list: provincesList, name:'name',value:'id'}}
                                            handleChange={(name, value) => { handleChange(name, value)}}/>
                                          <Dropdown disabled lable="Your current province or territory of residence if it is different than your mailing address above" name="" selected={statusPayload?.residency_status}/>
                                          <Dropdown disabled lable="Province or territory where your business had a permanent establishment if you were self-employed in 2022" name="" selected={statusPayload?.province_of_return}/>
                                        </div>
                                      </MaterialContainer>
                                      
                                      <MaterialContainer>
                                        <div className="flex flex-column gap-1">
                                          <div className="flex gap-1">
                                            <p style={{textAlign: 'justify'}} className="w-100">If you became a resident of Canada in 2022 for income tax purposes, enter your date of entry:</p>
                                            <div className="w-100">
                                              <Input disabled type="month" lable="(Month Day)"/>
                                            </div>
                                            
                                          </div>
                                          <div className="flex gap-1">
                                            <p style={{textAlign: 'justify'}} className="w-100">If you ceased to be a resident of Canada in 2022 for income tax purposes, enter your date of departure:</p>
                                            <div className="w-100">
                                              <Input disabled type="month" lable="(Month Day)" handleChange={(name, value) => { handleChange(name, value)}}/>
                                            </div>
                                          </div>
                                        </div>
                                      </MaterialContainer>                                      
                                    </div>
                                  </MaterialContainer>
                                  {/* <p><b></b></p> */}

                                  <MaterialContainer heading="Your spouse's or common-law partner's information">
                                    <div className="flex gap-1">
                                      <Input  lable="Their first name" disabled name="fname" value={spousePayload?.fname}/>
                                      <Input  lable="Their SIN" disabled name="sin" value={spousePayload?.sin}  />
                                    </div>
                                    <div className="">
                                      <CheckboxForm 
                                        checkboxes={[
                                          {title: 'Tick this box if they were self-employed in 2022.', count: true, name:'self_emp_2022',value:taxPayerPayload?.self_emp_2022}
                                        ]}
                                        handleChange={(name, value) => { handleChange(name, value)}}

                                      />
                                      <br/>
                                      <Form_UI_a name="" subTitle="Net income from line 23600 of their return to claim certain credits (or the amount that it would be if they filed a return, even if the amount is '0')" input2="yes" disabled value2="" hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                      <Form_UI_a name="" subTitle="Amount of universal child care benefit (UCCB) from line 11700 of their return" input2="yes" disabled value2="" hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                      <Form_UI_a name="" subTitle="Amount of UCCB repayment from line 21300 of their return" input2="yes" disabled value2="" hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    </div>
                                  </MaterialContainer>
                                  <MaterialContainer>
                                    <p><b>Elections Canada</b></p>
                                    <br/>
                                    <p>For more information, see "Elections Canada" in Step 1 of the guide.</p>
                                    <br/>
                                    <p style={{marginBottom: '-1.9em'}}>A) Do you have Canadian citizenship?</p>
                                    <Form_UI_c name="" subTitle="If yes, go to question B. If no, skip question B." lable_1="Yes" lable_2="No"/>

                                    {/* <p style={{marginBottom: '-1.9em'}}>A) Do you have Canadian citizenship?</p> */}
                                    <Form_UI_c name="" subTitle="As a Canadian citizen, do you authorize the CRA to give your name, address, date of birth, and citizenship to Elections Canada to update the National Register of Electors or, if you are 14 to 17 years of age, the Register of Future Electors? " lable_1="Yes" lable_2="No"/>
                                    <br/>
                                    <p>Your authorization is valid until you file your next tax return. Your information will only be used for purposes permitted
                                        under the Canada Elections Act, which include sharing lists of electors produced from the National Register of Electors
                                        with provincial and territorial electoral agencies, members of Parliament, registered and eligible political parties, and
                                        candidates at election time.</p>
                                    <br/>
                                    <p>Your information in the Register of Future Electors will be included in the National Register of Electors once you turn 18
                                        and your eligibility to vote is confirmed. Information from the Register of Future Electors can be shared only with provincial
                                        and territorial electoral agencies that are allowed to collect future elector information. In addition, Elections Canada can use
                                        information in the Register of Future Electors to provide youth with educational information about the electoral process.</p>
                                  </MaterialContainer>

                                  <MaterialContainer heading="Indian Act – Exempt income">
                                    
                                  <CheckboxForm 
                                        checkboxes={[
                                          {title: 'Tick this box if you have income that is exempt under the Indian Act. For more information about this type of income, go to canada.ca/taxes-indigenous-peoples. ', count: true, name:'',value:''}
                                        ]}
                                        handleChange={(name, value) => { handleChange(name, value)}}
                                      />
                                    <br/>
                                    <p>If you ticked the box above, complete Form T90, Income Exempt from Tax under the Indian Act, so that the CRA can
                                        calculate your Canada workers benefit for the 2022 tax year, if applicable, and your family's provincial or territorial benefits.
                                        The information you provide on Form T90 will also be used to calculate your Canada training credit limit for the 2023 tax year.</p>
                                  </MaterialContainer>

                                  <MaterialContainer heading="Climate action incentive payment">
                                    <CheckboxForm 
                                      checkboxes={[
                                        {title: 'Tick this box if you reside outside of the census metropolitan areas (CMA) of Barrie, Belleville-Quinte West, Brantford, Greater Sudbury, Guelph, Hamilton, Kingston, Kitchener-Cambridge-Waterloo, London, Oshawa, the Ontario part of Ottawa-Gatineau, Peterborough, St. Catharines-Niagara, Thunder Bay, Toronto, or Windsor, as determined by Statistics Canada (2021), and expect to continue to reside outside the same CMA on April 1, 2023.', count: true, name:'',value:''}
                                      ]}
                                      handleChange={(name, value) => { handleChange(name, value)}}
                                    />
                                    <p><b>Note:</b> If your marital status is married or living common-law, and both you and your spouse or common-law partner were
                                      residing in the same location outside of a CMA, you must tick this box on both of your returns.</p>
                                  </MaterialContainer>

                                  <MaterialContainer heading="Foreign property">
                                    <Form_UI_c name="" subTitle="Did you own or hold specified foreign property where the total cost amount of all such property, at any time in 2022, was more than CAN$100,000?" box="26600" lable_1="Yes" lable_2="No"/>

                                    <p>If <b>yes</b>, complete Form T1135, Foreign Income Verification Statement. There are substantial penalties for not filing Form T1135 by the due date. For more information, see Form T1135.</p>
                                  </MaterialContainer>

                                  <MaterialContainer heading="Consent to share contact information – Organ and tissue donor registry">
                                    <Form_UI_c name="" subTitle="I authorize the CRA to provide my name and email address to Ontario Health so that Ontario Health (Trillium Gift of Life) may contact or send information to me by email about organ and tissue donation." lable_1="Yes" lable_2="No"/>
                                    
                                    <p><b>Note:</b> You are not consenting to organ and tissue donation when you authorize the CRA to share your contact information with Ontario Health. Your authorization is valid only in the tax year that you file this tax return. Your information will only be collected under the Ontario Gift of Life Act</p>
                                  </MaterialContainer>
                                </MaterialContainer>
                                <br/>
                                
                                {/* <br/> */}

                                
                                <p>Complete only the lines that apply to you, unless stated otherwise. You can find more information about the lines on this return
                                by calling 1-800-959-8281 or by going to canada.ca/line-xxxxx and replacing "xxxxx" with any five-digit line number from this
                                return. For example, go to canada.ca/line-10100 for information about line 10100.</p>
                                
                                <br/>
                                <MaterialContainer heading="Step 2 – Total income">
                                    
                                    <p>As a resident of Canada, you need to report your income from all sources inside and outside Canada.</p>

                                    <Form_UI_a 
                                      name="10100" 
                                      subTitle="Employment income (box 14 of all T4 slips)" 
                                      input2="yes" 
                                      box2="10100" 
                                      div_3_a=" " 
                                      div_4="1" 
                                      disabled 
                                      value2={(payload?.line_10100?.value)} 
                                      hint='' 
                                      handleChange={(name, value) => 
                                      {handleChange(name, value)}}/>
                                    
                                    <Form_UI_a name="total_income_002" title="" subTitle="Tax-exempt income for emergency services volunteers (see line 10100 of the guide)" input1="yes" div_3_a=" " box="10105" div_4=" " value={(payload?.total_income_002)} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a 
                                      name_a="10120" 
                                      title="" 
                                      subTitle="Commissions included on line 10100 (box 42 of all T4 slips)" 
                                      input1="yes" 
                                      div_3_a=" " 
                                      box="10120"
                                      div_4=" " 
                                      value1={payload?.line_10120?.value} 
                                      hint='' 
                                      handleChange={(name, value) => {handleChange(name, value)}}/>

                                    <Form_UI_a name="total_income_004" title="" subTitle="Wage-loss replacement contributions (see line 10100 of the guide) " input1="yes" div_3_a=" " box="10130" div_4=" " value={payload?.total_income_004} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    
                                    <Form_UI_a name="total_income_005" title="" subTitle="Other employment income (see line 10400 of the guide)" input2="yes" div_3_a="+" box2="10400" div_4="2" value={payload?.total_income_005} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="total_income_006" title="" subTitle="Old age security (OAS) pension (box 18 of the T4A(OAS) slip)" input2="yes" div_3_a="+" box2="11300" div_4="3" value={payload?.total_income_006} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="total_income_007" title="" subTitle="CPP or QPP benefits (box 20 of the T4A(P) slip)" input2="yes" div_3_a="+" box2="11400" div_4="4" value={payload?.total_income_007} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>

                                    <Form_UI_a name="total_income_008" title="" subTitle="Disability benefits included on line 11400 (box 16 of the T4A(P) slip)" input1="yes" div_3_a=" " box="11410" div_4=" " value={payload?.total_income_008} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>

                                    <Form_UI_a name="total_income_009" title="" subTitle="Other pensions and superannuation (see line 11500 of the guide and line 31400 of the return)" input2="yes" div_3_a="+" box2="11500" div_4="5" value={payload?.total_income_009} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="total_income_010" title="" subTitle="Elected split-pension amount (complete Form T1032)" input2="yes" div_3_a="+" box2="11600" div_4="6" value={payload?.total_income_010} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="total_income_011" title="" subTitle="Universal child care benefit (UCCB) (see the RC62 slip)" input2="yes" div_3_a="+" box2="11700" div_4="7" value={payload?.total_income_011} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>

                                    <Form_UI_a name="total_income_012" title="" subTitle="UCCB amount designated to a dependant" input1="yes" div_3_a=" " box="11701" div_4=" " value={payload?.total_income_012} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>

                                    <Form_UI_a name="total_income_013" title="" subTitle="Employment insurance (EI) and other benefits (box 14 of the T4E slip)" input2="yes" div_3_a="+" box2="11900" div_4="8" value={payload?.total_income_013} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>

                                    <Form_UI_a name="total_income_014" title="" subTitle="EI maternity and parental benefits, and provincial parental insurance plan (PPIP) benefits" input1="yes" div_3_a=" " box="11905" div_4=" " value={payload?.total_income_014} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <p>Taxable amount of dividends from taxable Canadian corporations (use Federal Worksheet):</p>
                                    <Form_UI_a 
                                      name="12000" 
                                      title="" 
                                      subTitle="Amount of dividends (eligible and other than eligible)" 
                                      input2="yes" 
                                      div_3_a="+" 
                                      box2="12000" 
                                      div_4="9" 
                                      value2={(payload?.line_12000?.value)} 
                                      hint='' 
                                      handleChange={(name, value) => {handleChange(name, value)}}/>

                                    <Form_UI_a 
                                      name="line_12010" 
                                      title="" 
                                      subTitle="Amount of dividends (other than eligible) " 
                                      input1="yes" 
                                      div_3_a=" " 
                                      box="12010" 
                                      div_4=" " 
                                      value1={(payload?.line_12010?.value)} 
                                      hint='' 
                                      handleChange={(name, value) => {handleChange(name, value)}}/>

                                    <Form_UI_a 
                                      name="line_12100" 
                                      title="" 
                                      subTitle="Interest and other investment income (use Federal Worksheet)" 
                                      input2="yes" 
                                      div_3_a="+" 
                                      box2="12100" 
                                      div_4="10" 
                                      value2={(payload?.line_12100?.value)} 
                                      hint='' 
                                      handleChange={(name, value) => {handleChange(name, value)}}/>
                                    
                                    <Form_UI_a name="total_income_018" title="" subTitle="Net partnership income (limited or non-active partners only)" input2="yes" div_3_a="+" box2="12200" div_4="11" value={payload?.total_income_018} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    
                                    <Form_UI_a 
                                      name="" 
                                      title="" 
                                      subTitle="Registered disability savings plan (RDSP) income (box 131 of the T4A slip)" 
                                      input2="yes" 
                                      div_3_a="+" 
                                      box2="12500" 
                                      div_4="12" 
                                      value2={(payload?.line_12500?.value)} 
                                      hint='' 
                                      handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="total_income_020" title="" subTitle="Rental income (see Guide T4036)" box0="12599" div_1="Gross" input="yes" input1="Net" input2="yes" div_3_a="+" box2="12600" div_4="13" value={payload?.total_income_020} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="total_income_021" title="" subTitle="Taxable capital gains (complete Schedule 3)" input2="yes" div_3_a="+" box2="12700" div_4="14" value={payload?.total_income_021} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="total_income_022" title="" subTitle="Support payments received (see Guide P102)" box0="12799" div_1="Total" input="yes" input1="Taxable amount" input2="yes" div_3_a="+" box2="12800" div_4="15" value={payload?.total_income_022} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="total_income_023" title="" subTitle="Registered retirement savings plan (RRSP) income (from all T4RSP slips)" input2="yes" div_3_a="+" box2="12900" div_4="16" value={payload?.total_income_023} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="total_income_024" title="" subTitle="Other income" div_1="(specify):" input="yes" input2="yes" div_3_a="+" box2="13000" div_4="17" value={payload?.total_income_024} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="" title="" subTitle="Taxable scholarships, fellowships, bursaries, and artists' project grants" input2="yes" div_3_a="+" box2="13010" div_4="18" value2={payload?.box_25?.value} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="box_19" title="" subTitle="Add lines 1 to 18." input2="yes" div_3_a="=" box2="" div_4="19" value2={(payload?.box_19?.value)} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    
                                    <p><b>Self-employment income </b>(see Guide T4002):</p>
                                    <Form_UI_a name="total_income_027" title="" subTitle="Professional income" div_1="Gross" box0="13699" input="yes" input1="yes" div_2="Net" div_3="+" box="13700" div_3_a="20" value={payload?.total_income_027} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="total_income_028" title="" subTitle="Business income" div_1="Gross" box0="13499" input="yes" input1="yes" div_2="Net" div_3=" " box="13500" div_3_a="21" value={payload?.total_income_028} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="total_income_029" title="" subTitle="Commission income" div_1="Gross" box0="13899" input="yes" input1="yes" div_2="Net" div_3="+" box="13900" div_3_a="22" value={payload?.total_income_029} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="total_income_030" title="" subTitle="Farming income" div_1="Gross" box0="14099" input="yes" input1="yes" div_2="Net" div_3="+" box="14100" div_3_a="23" value={payload?.total_income_030} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="total_income_031" title="" subTitle="Fishing income" div_1="Gross" box0="14299" input="yes" input1="yes" div_2="Net" div_3="+" box="14300" div_3_a="24" value={payload?.total_income_031} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    
                                    <Form_UI_a name="box_25" title="" subTitle="Add lines 20 to 24." input="Net self-employment income" input1="yes" div_3="=" input2="yes" div_3_a=">" div_4="25" value2={(payload?.box_25?.value)} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="box_26" title="" subTitle="Line 19 plus line 25" input2="yes" div_3_a="=" div_4="26" value2={(payload?.box_26?.value)} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>

                                    <Form_UI_a name="total_income_034" title="" subTitle="Workers' compensation benefits (box 10 of the T5007 slip)" box="14400" input1="yes" div_3=" " div_3_a=" " div_4="27" value={payload?.total_income_034} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="total_income_035" title="" subTitle="Social assistance payments" box="14500" input1="yes" div_3="+" div_3_a=" " div_4="28" value={payload?.total_income_035} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="total_income_036" title="" subTitle="Net federal supplements paid (box 21 of the T4A(OAS) slip)" box="14600" input1="yes" div_3="+" div_3_a=" " div_4="29" value={payload?.total_income_036} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="line_14700" title="" subTitle="Add lines 27 to 29 (see line 25000 in Step 4)." box="14700" input1="yes" div_3="=" div_3_a="> +" input2="yes" div_4="30" value2={(payload?.line_14700?.value)} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="line_15000" title="" subTitle="Line 26 plus line 30" input1="Total income" box2="15000" div_3_a="="  input2="yes" div_4="31" value2={(payload?.line_15000?.value)} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>


                                </MaterialContainer>
                                <br />
                                <br />

                                <MaterialContainer heading="Step 3 – Net income">
                                    <Form_UI_a name="box_32" title="" subTitle="Enter the amount from line 31 of the previous page" input2="yes" div_3_a=" " div_4="32" value2={(payload?.box_32?.value)} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    
                                    <Form_UI_a name="line_20600" title="Pension adjustment" subTitle="(box 52 of all T4 slips and box 034 of all T4A slips)" box0="20600" input="yes" div_3_a=" " div_4=" " value={(payload?.line_20600?.value)} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    
                                    <Form_UI_a name="line_20700" title="Registered pension plan (RPP) deduction" subTitle="(box 20 of all T4 slips and box 032 of all T4A slips)" box="20700" input1="yes" div_3=" " div_3_a=" " div_4="33" value1={(payload?.line_20700?.value)} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="net_income_004" title="" subTitle="RRSP deduction (see Schedule 7 and attach receipts)" box="20800" input1="yes" div_3="+" div_3_a=" " div_4="34" value={payload?.net_income_004} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    
                                    <Form_UI_a name="net_income_005" title="Pooled registered pension plan (PRPP) employer contributions" subTitle="(amount from your PRPP contribution receipts)" box0="20810" input="yes" div_3="" div_3_a=" " div_4=" " value={payload?.net_income_005} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    
                                    <Form_UI_a name="net_income_006" title="" subTitle="Deduction for elected split-pension amount (Complete Form T1032)"  box="21000" input1="yes" div_3="+" div_3_a=" " div_4="35" value={payload?.net_income_006} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="line_21200" title="" subTitle="Annual union, professional, or like dues (receipts and box 44 of all T4 slips)" box="21200" input1="yes" div_3="+" div_3_a=" " div_4="36" value1={(payload?.line_21200?.value)} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="net_income_008" title="" subTitle="Universal child care benefit (UCCB) repayment (box 12 of all RC62 slips)" box="21300" input1="yes" div_3="+" div_3_a=" " div_4="37" value={payload?.net_income_008} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="net_income_009" title="" subTitle="Child care expenses (complete Form T778)" box="21400" input1="yes" div_3="+" div_3_a=" " div_4="38" value={payload?.net_income_009} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="net_income_010" title="" subTitle="Disability supports deduction (complete Form T929)" box="21500" input1="yes" div_3="+" div_3_a=" " div_4="39" value={payload?.net_income_010} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <p>Business investment loss (see Guide T4037)</p>
                                    <Form_UI_a name="net_income_011" title="" subTitle="" div_1="Gross" box0="21699" input="yes" div_2="Allowable deduction" box="21700" input1="yes" div_3="+" div_3_a=" " div_4="40" value={payload?.net_income_011} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="net_income_012" title="" subTitle="Moving expenses (complete Form T1-M)" box="21900" input1="yes" div_3="+" div_3_a=" " div_4="41" value={payload?.net_income_012} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <p>Support payments made (see Guide P102)</p>
                                    <Form_UI_a name="net_income_013" title="" subTitle="" div_1="Gross" box0="21999" input="yes" div_2="Allowable deduction" box="22000" input1="yes" div_3="+" div_3_a=" " div_4="42" value={payload?.net_income_013} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="net_income_014" title="Carrying charges, interest expenses, and other expenses " subTitle="(use Federal Worksheet)" box="22100" input1="yes" div_3="+" div_3_a=" " div_4="43" value={payload?.net_income_014} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="net_income_015" title="" subTitle="Deduction for CPP or QPP contributions on self-employment income and other earnings (complete Schedule 8 or Form RC381, whichever applies) " box="22200" input1="yes" div_3="+" div_3_a=" " div_4="44" value={payload?.net_income_015} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="line_22215" title="" subTitle="Deduction for CPP or QPP enhanced contributions on employment income (complete Schedule 8 or Form RC381, whichever applies) (maximum $460.50)" box="22215" input1="yes" div_3="+" div_3_a=" " div_4="45" value1={(payload?.line_22215?.value)} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="" title="" subTitle="Exploration and development expenses (complete Form T1229) " box="22400" input1="yes" div_3="+" div_3_a=" " div_4="46" value1={payload?.line_22400?.value} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="net_income_018" title="" subTitle="Other employment expenses (see Guide T4044)" box="22900" input1="yes" div_3="+" div_3_a=" " div_4="47" value={payload?.net_income_018} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="net_income_019" title="" subTitle="Clergy residence deduction (complete Form T1223) " box="23100" input1="yes" div_3="+" div_3_a=" " div_4="48" value={payload?.net_income_019} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="net_income_020" title="" subTitle="Other deductions" box="23200" input1="yes" div_3="+" div_3_a=" " div_4="49" value={payload?.net_income_020} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="net_income_021" title="" subTitle="Federal COVID-19 benefits repayment (box 201 of all federal T4A slips)" box="23210" input1="yes" div_3="+" div_3_a=" " div_4="50" value={payload?.net_income_021} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="line_23300" title="" subTitle="Add lines 33 to 50." box="23300" input1="yes" input2="yes" div_3="=" div_3_a="> -" div_4="51" value2={(payload?.line_23300?.value)} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="line_23400" title="" subTitle="Line 32 minus line 51 (if negative, enter '0')" input1="Net income before adjustments" box2="23400" input2="yes" div_3=" " div_3_a="=" div_4="52" value2={(payload?.line_23400?.value)} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>

                                    <p><b>Social benefits repayment:</b>
                                        <br/>
                                        Complete the chart for line 23500 using your Federal Worksheet if one or more of the following apply:
                                        <br/>
                                        <br/>
                                        You entered an amount for EI and other benefits on line 11900 and the amount on line 23400 is more than $75,375
                                        <br/>
                                        You entered an amount for OAS pension on line 11300 or net federal supplements paid on line 14600 and the amount on line 23400 is more than $81,761
                                        <br/>
                                    </p>

                                    <Form_UI_a name="line_23500" title="" subTitle="If not, enter '0' on line 23500" box2="23500" input2="yes" div_3=" " div_3_a="-" div_4="53" value2={(payload?.line_23500?.value)} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <p>Line 52 minus line 53 (if negative, enter "0")</p>
                                    <Form_UI_a name="line_23600" title="" subTitle="(If this amount is negative, you may have a non-capital loss. See Form T1A.)" input1="Net income" box2="23600" input2="yes" div_3=" " div_3_a="=" div_4="54" value2={(payload?.line_23600?.value)} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>

                                </MaterialContainer>
                                <br />
                                <br />

                                <MaterialContainer heading="Step 4 – Taxable income">
                                    <Form_UI_a name="box_55" title="" subTitle="Enter the amount from line 54 of the previous page." input2="yes" div_3_a=" " div_4="55" value2={(payload?.box_55?.value)} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="line_24400" title="" subTitle="Canadian Armed Forces personnel and police deduction (box 43 of all T4 slips)" box="24400" div_3=" " input1="yes" div_3_a=" " div_4="56" value1={(payload?.line_24400?.value)} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="line_24900" title="" subTitle="Security options deductions (boxes 39 and 41 of T4 slips or see Form T1212)" box="24900" div_3="+" input1="yes" div_3_a=" " div_4="57" value1={(payload?.line_24900?.value)} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="line_25000" title="" subTitle="Other payments deduction (enter the amount from line 14700 if you did not enter an amount on line 14600; otherwise, use Federal Worksheet)" box="25000" div_3="+" input1="yes" div_3_a=" " div_4="58" value1={(payload?.line_25000?.value)} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="taxable_income_005" title="" subTitle="Limited partnership losses of other years" box="25100" div_3="+" input1="yes" div_3_a=" " div_4="59" value={payload?.taxable_income_005} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="taxable_income_006" title="" subTitle="Non-capital losses of other years" box="25200" div_3="+" input1="yes" div_3_a=" " div_4="60" value={payload?.taxable_income_006} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="taxable_income_007" title="" subTitle="Net capital losses of other years" box="25300" div_3="+" input1="yes" div_3_a=" " div_4="61" value={payload?.taxable_income_007} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="taxable_income_008" title="" subTitle="Capital gains deduction (complete Form T657) " box="25400" div_3="+" input1="yes" div_3_a=" " div_4="62" value={payload?.taxable_income_008} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="taxable_income_009" title="" subTitle="Northern residents deductions (complete Form T2222) " box="25500" div_3="+" input1="yes" div_3_a=" " div_4="63" value={payload?.taxable_income_009} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="taxable_income_010" title="" subTitle="Additional deductions" div_1="(specify):" input="yes" box="25600" div_3="+" input1="yes" div_3_a=" " div_4="64" value={payload?.taxable_income_010} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    
                                    <Form_UI_a name="line_25700" title="" subTitle="Add lines 56 to 64." box="25700" div_3="=" input1="yes" div_3_a="> -" input2="yes" div_4="65" value2={(payload?.line_25700?.value)} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="taxable_income_012" title="" subTitle="Line 55 minus line 65 (if negative, enter '0') " input1="Taxable income" box2="26000" input2="yes" div_3=" " div_3_a="=" div_4="66" value2={(payload?.line_26000?.value)} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>

                                </MaterialContainer>
                                <br />
                                <br />
                                <MaterialContainer heading="Step 5 – Federal tax">
                                    <h3>Part A – Federal tax on taxable income</h3>

                                    <p>Use the amount from line 26000 to complete the appropriate column below</p>

                                    <br/>

                                    <div className="w-100 flex">
                                        <div className="w-100 flex-centered">
                                            <p></p>
                                        </div>
                                        <div className="" style={{minWidth: '2%'}}></div>

                                        <div className="w-100 flex-centered">
                                            <p>Line 26000 is $50,197 or less</p>
                                        </div>
                                        <div className="" style={{minWidth: '2%'}}></div>

                                        <div className="w-100 flex-centered">
                                            <p>Line 26000 is more than $50,197 but not more than $100,392 </p>
                                        </div>
                                        <div className="" style={{minWidth: '2%'}}></div>

                                        <div className="w-100 flex-centered">
                                            <p> Line 26000 is more than $100,392 but not more than $155,625</p>
                                        </div>
                                        <div className="" style={{minWidth: '2%'}}></div>

                                        <div className="w-100 flex-centered">
                                            <p>Line 26000 is more than $155,625 but not more than $221,708</p>
                                        </div>
                                        <div className="" style={{minWidth: '2%'}}></div>

                                        <div className="w-100 flex-centered">
                                            <p>Line 26000 is more than $221,708</p>
                                        </div>
                                    </div>
                                    <div className="w-100 flex flex-column ">
                                        {/* <div className="w-100 flex-centered"> */}
                                            <Form_UI_b title="" 
                                                subTitle="Amount from line 26000" 
                                                div_1=" "
                                                input1="yes" 
                                                Iname1="federal_tax_001_a"
                                                div_2=" "
                                                input2="yes" 
                                                Iname2="federal_tax_001_b"
                                                div_3=" "
                                                input3="yes" 
                                                Iname3="federal_tax_001_c"
                                                div_4=" "
                                                input4="yes" 
                                                Iname4="federal_tax_001_d"
                                                div_5=" "
                                                input5="yes" 
                                                Iname5="box_67"
                                                div_6="67"
                                                Ivalue1={(payload?.box_67_A?.value)} 
                                                Ivalue2={payload?.box_67_B?.value} 
                                                Ivalue3={payload?.box_67_C?.value} 
                                                Ivalue4={payload?.box_67_D?.value} 
                                                Ivalue5={payload?.box_67_E?.value} 
                                                handleChange={(name, value) => {handleChange(name, value)}}/>
                                        <Form_UI_b title="" 
                                                subTitle="Line 67 minus line 68 – (cannot be negative) " 
                                                div_1="-"
                                                input1="yes" 
                                                Iname1="box_68_A"
                                                div_2="-"
                                                input2="yes" 
                                                Iname2="federal_tax_002_b"
                                                div_3="-"
                                                input3="yes" 
                                                Iname3="federal_tax_002_c"
                                                div_4="-"
                                                input4="yes" 
                                                Iname4="federal_tax_002_d"
                                                div_5="-"
                                                input5="yes" 
                                                Iname5="box_68"
                                                div_6="68"
                                                Ivalue1={payload?.box_68_A?.value} 
                                                Ivalue2={payload?.box_68_B?.value} 
                                                Ivalue3={payload?.box_68_C?.value} 
                                                Ivalue4={payload?.box_68_D?.value} 
                                                Ivalue5={payload?.box_68_E?.value} 
                                                handleChange={(name, value) => {handleChange(name, value)}}/>
                                        <Form_UI_b title="" 
                                                subTitle="" 
                                                div_1="="
                                                input1="yes" 
                                                Iname1="federal_tax_002_a"
                                                div_2="="
                                                input2="yes" 
                                                Iname2="federal_tax_002_b"
                                                div_3="="
                                                input3="yes" 
                                                Iname3="federal_tax_002_c"
                                                div_4="="
                                                input4="yes" 
                                                Iname4="federal_tax_002_d"
                                                div_5="="
                                                input5="yes" 
                                                Iname5="box_69"
                                                div_6="69"
                                                Ivalue1={payload?.box_69_A?.value} 
                                                Ivalue2={payload?.box_69_B?.value} 
                                                Ivalue3={payload?.box_69_C?.value} 
                                                Ivalue4={payload?.box_69_D?.value} 
                                                Ivalue5={payload?.box_69_E?.value}  
                                                handleChange={(name, value) => {handleChange(name, value)}}/>
                                        <Form_UI_70 title="" 
                                                subTitle="Line 69 multiplied by the percentage from line 70" 
                                                div_1="x"
                                                input1="yes" 
                                                Iname1="federal_tax_003_a"
                                                div_2="x"
                                                input2="yes" 
                                                Iname2="federal_tax_003_b"
                                                div_3="x"
                                                input3="yes" 
                                                Iname3="federal_tax_003_c"
                                                div_4="x"
                                                input4="yes" 
                                                Iname4="federal_tax_003_d"
                                                div_5="x"
                                                input5="yes" 
                                                Iname5="box_70"
                                                div_6="70"
                                                Ivalue1={payload?.box_70_A?.value} 
                                                Ivalue2={payload?.box_70_B?.value} 
                                                Ivalue3={payload?.box_70_C?.value} 
                                                Ivalue4={payload?.box_70_D?.value} 
                                                Ivalue5={payload?.box_70_E?.value}  
                                                handleChange={(name, value) => {handleChange(name, value)}}/>
                                        <Form_UI_b title="" 
                                                subTitle="" 
                                                div_1="="
                                                input1="yes" 
                                                Iname1="federal_tax_003_a"
                                                div_2="="
                                                input2="yes" 
                                                Iname2="federal_tax_003_b"
                                                div_3="="
                                                input3="yes" 
                                                Iname3="federal_tax_003_c"
                                                div_4="="
                                                input4="yes"
                                                Iname4="federal_tax_003_d"
                                                div_5="="
                                                input5="yes" 
                                                Iname5="box_71"
                                                div_6="71"
                                                Ivalue1={payload?.box_71_A?.value} 
                                                Ivalue2={payload?.box_71_B?.value} 
                                                Ivalue3={payload?.box_71_C?.value} 
                                                Ivalue4={payload?.box_71_D?.value} 
                                                Ivalue5={payload?.box_71_E?.value}  
                                                handleChange={(name, value) => {handleChange(name, value)}}/>
                                        <Form_UI_b title="" 
                                                subTitle="Line 71 plus line 72" 
                                                div_1="+"
                                                input1="yes" 
                                                Iname1="federal_tax_005_a"
                                                div_2="+"
                                                input2="yes" 
                                                Iname2="federal_tax_005_b"
                                                div_3="+"
                                                input3="yes" 
                                                Iname3="federal_tax_005_c"
                                                div_4="+"
                                                input4="yes" 
                                                Iname4="federal_tax_005_d"
                                                div_5="+"
                                                input5="yes" 
                                                Iname5="box_72"
                                                div_6="72"
                                                Ivalue1={payload?.box_72_A?.value} 
                                                Ivalue2={payload?.box_72_B?.value} 
                                                Ivalue3={payload?.box_72_C?.value} 
                                                Ivalue4={payload?.box_72_D?.value} 
                                                Ivalue5={payload?.box_72_E?.value}  
                                                handleChange={(name, value) => {handleChange(name, value)}}/>
                                        <Form_UI_b title="" 
                                                subTitle="Federal tax on taxable income" 
                                                div_1="="
                                                input1="yes" 
                                                Iname1="federal_tax_004_a"
                                                div_2="="
                                                input2="yes" 
                                                Iname2="federal_tax_004_b"
                                                div_3="="
                                                input3="yes" 
                                                Iname3="federal_tax_004_c"
                                                div_4="="
                                                input4="yes" 
                                                Iname4="federal_tax_004_d"
                                                div_5="="
                                                input5="yes" 
                                                Iname5="box_73"
                                                div_6="73"
                                                Ivalue1={payload?.box_73_A?.value} 
                                                Ivalue2={payload?.box_73_B?.value} 
                                                Ivalue3={payload?.box_73_C?.value} 
                                                Ivalue4={payload?.box_73_D?.value} 
                                                Ivalue5={payload?.box_73_E?.value}  
                                                handleChange={(name, value) => {handleChange(name, value)}}/>
                                        
                                        <p>Enter the amount from line 73 on line 116 and continue at line 74.</p>
                                        <br/><br/>
                                        <h3>Part B – Federal non-refundable tax credits</h3>
                                        <br/>
                                        <p>Basic personal amount:</p>
                                        <p>If the amount on line 23600 is $155,625 or less, enter $14,398. </p>
                                        <p>If the amount on line 23600 is $221,708 or more, enter $12,719.</p>
                                        
                                        <Form_UI_a name="line_30000" title="" subTitle="Otherwise, use the Federal Worksheet to calculate the amount to enter." input1="(maximum $14,398)" input2="yes" box2="30000" div_3_a=" " div_4="74" value2={(payload?.line_30000?.value)} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                        <Form_UI_a name="fed_nr_tax_cred_002" title="" subTitle="Age amount (if you were born in 1957 or earlier) (use Federal Worksheet)" input1="(maximum $7,898)" input2="yes" box2="30100" div_3_a="+" div_4="75" value={payload?.fed_nr_tax_cred_002} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                        <Form_UI_a name="fed_nr_tax_cred_003" title="" subTitle="Spouse or common-law partner amount (complete Schedule 5)" input1="" input2="yes" box2="30300" div_3_a="+" div_4="76" value={payload?.fed_nr_tax_cred_003} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                        <Form_UI_a name="fed_nr_tax_cred_004" title="" subTitle="Amount for an eligible dependant (complete Schedule 5)" input1="" input2="yes" box2="30400" div_3_a="+" div_4="77" value={payload?.fed_nr_tax_cred_004} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                        <Form_UI_a name="fed_nr_tax_cred_005" title="" subTitle="Canada caregiver amount for spouse or common-law partner, or eligible dependant age 18 or older (complete Schedule 5)" input1="" input2="yes" box2="30425" div_3_a="+" div_4="78" value={payload?.fed_nr_tax_cred_005} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                        <Form_UI_a name="fed_nr_tax_cred_006" title="" subTitle="Canada caregiver amount for other infirm dependants age 18 or older (complete Schedule 5)" input1="" input2="yes" box2="30450" div_3_a="+" div_4="79" value={payload?.fed_nr_tax_cred_006} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                        <p>Canada caregiver amount for infirm children under 18 years of age (see Schedule 5)</p>
                                        <Form_UI_a name="fed_nr_tax_cred_007" title="" subTitle="Number of children you are claiming this amount for" box0="30499" input="yes" div_3="x" input1="$2,350" input2="yes" div_3_a0="="  box2="30500" div_3_a="+" div_4="80" value={payload?.fed_nr_tax_cred_007} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                        <Form_UI_a name="box_81" title="" subTitle="Add lines 74 to 80." input2="yes" div_3_a="=" div_4="81" value2={payload?.box_81?.value} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>

                                        <br/>

                                        <Form_UI_a name="box_82" title="" subTitle="Enter the amount from line 81 of the previous page." input2="yes" div_3_a=" " div_4="82" value2={payload?.box_82?.value} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                        
                                        <p>Base CPP or QPP contributions (complete Schedule 8 or Form RC381, whichever applies):</p>
                                        <Form_UI_a name="line_30800" title="" subTitle="through employment income" input1="yes" box="30800" div_3=" " div_4="83" value1={payload?.line_30800?.value} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                        <Form_UI_a name="fed_nr_tax_cred_011" title="" subTitle="on self-employment income and other earnings " input1="yes" box="31000" div_3="+" div_4="84" value={payload?.fed_nr_tax_cred_011} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>

                                        <p>Employment insurance premiums:</p>
                                        <Form_UI_a name="fed_nr_tax_cred_012" title="" subTitle="through employment (boxes 18 and 55 of all T4 slips) (maximum $952.74)" input1="yes" box="31200" div_3="+" div_4="85" value1={payload?.line_31200?.value} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                        <Form_UI_a name="fed_nr_tax_cred_013" title="" subTitle="on self-employment and other eligible earnings (complete Schedule 13)" input1="yes" box="31217" div_3="+" div_4="86" value={payload?.fed_nr_tax_cred_013} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>

                                        <Form_UI_a name="fed_nr_tax_cred_014" title="" subTitle="Volunteer firefighters' amount (VFA)" input1="yes" box="31220" div_3="+" div_4="87" value={payload?.fed_nr_tax_cred_014} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                        <Form_UI_a name="fed_nr_tax_cred_015" title="" subTitle="Search and rescue volunteers' amount (SRVA)" input1="yes" box="31240" div_3="+" div_4="88" value={payload?.fed_nr_tax_cred_015} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                        
                                        <p>Canada employment amount: </p>
                                        <Form_UI_a name="line_31260" title="" subTitle="Enter whichever is less: $1,287 or line 1 plus line 2" input1="yes" box="31260" div_3="+" div_4="89" value1={payload?.line_31260?.value} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                        <Form_UI_a name="fed_nr_tax_cred_017" title="" subTitle="Home buyers' amount " input1="yes" box="31270" div_3="+" div_4="90" value={payload?.fed_nr_tax_cred_017} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                        <Form_UI_a name="fed_nr_tax_cred_018" title="" subTitle="Home accessibility expenses (use Federal Worksheet)" input1="yes" box="31285" div_3="+" div_4="91" value={payload?.fed_nr_tax_cred_018} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                        <Form_UI_a name="fed_nr_tax_cred_019" title="" subTitle="Adoption expenses" input1="yes" box="31300" div_3="+" div_4="92" value={payload?.fed_nr_tax_cred_019} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                        <Form_UI_a name="fed_nr_tax_cred_020" title="" subTitle="Digital news subscription expenses (see line 31350 of the guide)" input1="yes" box="31350" div_3="+" div_4="93" value={payload?.fed_nr_tax_cred_020} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                        <Form_UI_a name="box_94" title="" subTitle="Add lines 83 to 93." input1="yes"div_3="=" box="" div_3_a="> +" input2="yes" div_4="94" value2={payload?.box_94?.value} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>

                                        <Form_UI_a name="fed_nr_tax_cred_022" title="" subTitle="Pension income amount (use Federal Worksheet) " input1="(maximum $2,000)" input2="yes" box2="31400" div_3_a="+" div_4="95" value={payload?.fed_nr_tax_cred_022} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                        <Form_UI_a name="box_96" title="" subTitle="Add lines 82, 94, and 95" input1="" input2="yes" box2="" div_3_a="=" div_4="96" value2={payload?.box_96?.value} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                        <p>Disability amount for self</p>
                                        <Form_UI_a name="fed_nr_tax_cred_024" title="" subTitle="(if you were under 18 years of age, use Federal Worksheet; if not, claim $8,870)" input1="" input2="yes" box2="31600" div_3_a="+" div_4="97" value={payload?.fed_nr_tax_cred_024} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                        <Form_UI_a name="fed_nr_tax_cred_025" title="" subTitle="Disability amount transferred from a dependant (use Federal Worksheet)" input1="" input2="yes" box2="31800" div_3_a="+" div_4="98" value={payload?.fed_nr_tax_cred_025} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                        <Form_UI_a name="box_99" title="" subTitle="Add lines 96 to 98." input1="" input2="yes" box2="" div_3_a="=" div_4="99" value2={payload?.box_99?.value} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                        <Form_UI_a name="fed_nr_tax_cred_027" title="" subTitle="Interest paid on your student loans (see Guide P105)" input1="" input2="yes" box2="31900" div_3_a="+" div_4="100" value={payload?.fed_nr_tax_cred_027} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                        <Form_UI_a name="fed_nr_tax_cred_028" title="" subTitle="Your tuition, education, and textbook amounts (complete Schedule 11)" input1="" input2="yes" box2="32300" div_3_a="+" div_4="101" value={payload?.fed_nr_tax_cred_028} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                        <Form_UI_a name="fed_nr_tax_cred_029" title="" subTitle="Tuition amount transferred from a child or grandchild" input1="" input2="yes" box2="32400" div_3_a="+" div_4="102" value={payload?.fed_nr_tax_cred_029} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                        <Form_UI_a name="fed_nr_tax_cred_030" title="" subTitle="Amounts transferred from your spouse or common-law partner (complete Schedule 2)" input1="" input2="yes" box2="32600" div_3_a="+" div_4="103" value={payload?.fed_nr_tax_cred_030} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                        <Form_UI_a name="box_104" title="" subTitle="Add lines 99 to 103" input1="" input2="yes" box2="" div_3_a="=" div_4="104" value2={payload?.box_104?.value} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>

                                        <Form_UI_a name="fed_nr_tax_cred_032" title="" subTitle="Medical expenses for self, spouse or common-law partner, and your dependent children under 18 years of age" input1="yes" box="33099" div_3=" " div_4="105" value={payload?.fed_nr_tax_cred_032} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                        <Form_UI_a name="box_106" title="" subTitle="Amount from line 23600" input="yes" div_2="x 3% " input1="yes" box="" div_3="= " div_4="106" value1={payload?.box_106?.value} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                        <Form_UI_a name="box_107" title="" subTitle="Enter whichever is less: $2,479 or the amount from line 106. " input1="yes" box="" div_3="-" div_4="107" value1={payload?.box_107?.value} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                        <Form_UI_a name="fed_nr_tax_cred_035" title="" subTitle="Line 105 minus line 107 (if negative, enter '0')" input1="yes" box="" div_3="=" div_4="108" value={payload?.fed_nr_tax_cred_035} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                        <Form_UI_a name="fed_nr_tax_cred_036" title="" subTitle="Allowable amount of medical expenses for other dependants (use Federal Worksheet)" input1="yes" box="33199" div_3="+" div_4="109" value={payload?.fed_nr_tax_cred_036} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                        <Form_UI_a name="fed_nr_tax_cred_037" title="" subTitle="Line 108 plus line 109" input1="yes" box="33200" div_3="=" div_3_a="> +" input2="yes" div_4="110" value={payload?.fed_nr_tax_cred_037} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>

                                        <Form_UI_a name="line_33500" title="" subTitle="Line 104 plus line 110" input1="" box2="33500" div_3_a="=" input2="yes" div_4="111" value2={payload?.line_33500?.value} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                        <Form_UI_input name="box_112" title="" subTitle="Federal non-refundable tax credit rate" input1="" box="" div_3_a="x" input2="yes" div_4="112" style={{textAlign: 'right'}} value2={payload?.box_112?.value} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                        <Form_UI_a name="line_33800" title="" subTitle="Line 111 multiplied by the percentage from line 112" input1="" box2="33800" div_3_a="=" input2="yes" div_4="113" value2={payload?.line_33800?.value} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                        <Form_UI_a name="fed_nr_tax_cred_041" title="" subTitle="Donations and gifts (complete Schedule 9)" input1="" box2="34900" div_3_a="+" input2="yes" div_4="114" value={payload?.fed_nr_tax_cred_041} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                        <Form_UI_a name="line_35000" title="" subTitle="Line 113 plus line 114" input1="Total federal non-refundable tax credits" box2="35000" div_3_a="=" input2="yes" div_4="115" value2={payload?.line_35000?.value} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                        <br/>
                                        <h3>Part C - Net Federal Tax</h3>
                                        <Form_UI_a name="box_116" title="" subTitle="Enter the amount from line 73" input1="" box2="" div_3_a=" " input2="yes" div_4="116" value2={payload?.box_116?.value} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                        <Form_UI_a name="fed_nr_tax_cred_044" title="" subTitle="Federal tax on split income (TOSI) (complete Form T1206)" input1="" box2="40424" div_3_a="+" input2="yes" div_4="117" value={payload?.fed_nr_tax_cred_044} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                        <Form_UI_a name="line_40400" title="" subTitle="Line 116 plus line 117" input1="" box2="40400" div_3_a="=" input2="yes" div_4="118" value2={payload?.line_40400?.value} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>

                                        <Form_UI_a name="box_119" title="" subTitle="Amount from line 35000" input1="yes" box="" div_3=" " input2="" div_3_a=" " div_4="119" value1={payload?.box_119?.value} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                        <Form_UI_a name="line_40425" title="" subTitle="Federal dividend tax credit (use Federal Worksheet)" input1="yes" box="40425" div_3="+" div_3_a=" " input2="" div_4="120" value1={payload?.line_40425?.value} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                        <Form_UI_a name="fed_nr_tax_cred_048" title="" subTitle="Minimum tax carryover (complete Form T691)" input1="yes" box="40427" div_3="+" div_3_a=" " input2="" div_4="121" value={payload?.fed_nr_tax_cred_048} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                        <Form_UI_a name="box_122" title="" subTitle="Add lines 119 to 121." input1="yes" box="" div_3="=" div_3_a="> -" input2="yes" div_4="122" value2={payload?.box_122?.value} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>

                                        <Form_UI_a name="line_42900" title="" subTitle="Line 118 minus line 122 (if negative, enter '0')" input1="Basic federal tax" box2="42900" div_3_a="=" input2="yes" div_4="123" value2={payload?.line_42900?.value} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                        <Form_UI_a name="fed_nr_tax_cred_051" title="" subTitle="Federal surtax on income earned outside Canada (complete Form T2203)" input1="" box2="" div_3_a="+" input2="yes" div_4="124" value={payload?.fed_nr_tax_cred_051} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                        <Form_UI_a name="box_125" title="" subTitle="Line 123 plus line 124" input1="" box2="" div_3_a="=" input2="yes" div_4="125" value2={payload?.box_125?.value} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                        <Form_UI_a name="fed_nr_tax_cred_053" title="" subTitle="Federal foreign tax credit (complete Form T2209)" input1="" box2="40500" div_3_a="-" input2="yes" div_4="126" value={payload?.fed_nr_tax_cred_053} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                        <Form_UI_a name="box_127" title="" subTitle="Line 125 minus line 126" input1="" box2="" div_3_a="=" input2="yes" div_4="127" value2={payload?.box_127?.value} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                        <Form_UI_a name="fed_nr_tax_cred_055" title="" subTitle="Recapture of investment tax credit (complete Form T2038(IND)) " input1="" box2="" div_3_a="+" input2="yes" div_4="128" value={payload?.fed_nr_tax_cred_055} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                        <Form_UI_a name="box_129" title="" subTitle="Line 127 plus line 128 " input1="" box2="" div_3_a="=" input2="yes" div_4="129" value2={payload?.box_129?.value} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                        <Form_UI_a name="fed_nr_tax_cred_057" title="" subTitle="Federal logging tax credit (see guide)" input1="" box2="" div_3_a="-" input2="yes" div_4="130" value={payload?.fed_nr_tax_cred_057} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                        <Form_UI_a name="line_40600" title="" subTitle="Line 129 minus line 130 (if negative, enter '0')" input1="Federal Tax" box2="40600" div_3_a="=" input2="yes" div_4="131" value2={payload?.line_40600?.value} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>

                                        <p>Federal political contribution tax credit (use Federal Worksheet)</p>
                                        <p>Total federal political contributions</p>
                                        <Form_UI_a name="fed_nr_tax_cred_059" title="" subTitle="(attach receipts) " box0="40900" input="yes" input1="(maximum $650)" box2="41000" div_3_a=" " input2="yes" div_4="132" value={payload?.fed_nr_tax_cred_059} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                        <Form_UI_a name="fed_nr_tax_cred_060" title="" subTitle="Investment tax credit (complete Form T2038(IND)) " input1="" box2="41200" div_3_a=" " input2="yes" div_4="133" value={payload?.fed_nr_tax_cred_060} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                        
                                        <p>Labour-sponsored funds tax credit (see line 41400 of the guide)</p>
                                        <p>Net cost of shares of a provincially </p>
                                        <Form_UI_a name="fed_nr_tax_cred_061" title="" subTitle="registered fund" box0="41300" input="yes" input1="Allowable Credit" box2="41400" div_3_a="+" input2="yes" div_4="134" value={payload?.fed_nr_tax_cred_061} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>

                                        <Form_UI_a name="fed_nr_tax_cred_062" title="" subTitle="Add lines 132 to 134." box="41600" input1="yes" div_3="=" box2="" div_3_a="> -" input2="yes" div_4="135" value={payload?.fed_nr_tax_cred_062} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>

                                        <Form_UI_a name="line_41700" title="" subTitle="Line 131 minus line 135 (if negative, enter '0')" input1="" box2="41700" div_3_a="=" input2="yes" div_4="136" value2={payload?.line_41700?.value} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                        <Form_UI_a name="fed_nr_tax_cred_064" title="" subTitle="Canada workers benefit (CWB) advance payments received (box 10 of the RC210 slip)" input1="" box2="41500" div_3_a="+" input2="yes" div_4="137" value={payload?.fed_nr_tax_cred_064} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                        <Form_UI_a name="fed_nr_tax_cred_065" title="" subTitle="Special taxes (see line 41800 of the guide)" input1="" box2="41800" div_3_a="+" input2="yes" div_4="138" value={payload?.fed_nr_tax_cred_065} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                        <Form_UI_a name="line_42000" title="" subTitle="Add lines 136 to 138." input1="Net federal tax" box2="42000" div_3_a="=" input2="yes" div_4="139" value2={payload?.line_42000?.value} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>

                                        {/* </div> */}
                                    </div>
                                </MaterialContainer>
                                <br />
                                <br />
                                <MaterialContainer heading="Refund or balance owing">
                                    <Form_UI_a name="box_140" title="" subTitle="Amount from line 42000" input1="" box2="" div_3_a=" " input2="yes" div_4="140" value2={payload?.box_140?.value} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <p>CPP contributions payable on self-employment income and other earnings </p>
                                    <Form_UI_a name="refund_bal_owing_002" title="" subTitle="(complete Schedule 8 or Form RC381, whichever applies)" input1="" box2="42100" div_3_a="+" input2="yes" div_4="141" value={payload?.refund_bal_owing_002} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <p>Employment insurance premiums payable on self-employment and other eligible earnings</p>
                                    <Form_UI_a name="refund_bal_owing_003" title="" subTitle="(complete Schedule 13)" input1="" box2="42120" div_3_a="+" input2="yes" div_4="142" value={payload?.refund_bal_owing_003} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="line_42200" title="" subTitle="Social benefits repayment (amount from line 23500)" input1="" box2="42200" div_3_a="+" input2="yes" div_4="143" value2={payload?.line_42200?.value} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <b>Provincial or territorial tax</b>
                                    <Form_UI_a name="line_42800" title="" subTitle="(complete and attach your provincial or territorial Form 428, even if the result is '0')" input1="" box2="42800" div_3_a="+" input2="yes" div_4="144" value2={payload?.line_42800?.value} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="line_43500" title="" subTitle="Add lines 140 to 144." input1="Total Payable" box2="43500" div_3_a="=" input2="yes" div_4="145" value2={payload?.line_43500?.value} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="box_146" title="" subTitle="Enter the amount from line 145 of the previous page." input1="" box2="" div_3_a=" " input2="yes" div_4="146" value2={payload?.box_146?.value} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>

                                    <Form_UI_a name="line_43700" title="" subTitle="Total income tax deducted (amounts from all Canadian slips)" input1="yes" box="43700" div_3_a=" " div_3=" " input2="" div_4="147" value1={payload?.line_43700?.value} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="refund_bal_owing_009" title="" subTitle="Refundable Quebec abatement (see line 44000 of the guide)" input1="yes" box="44000" div_3_a=" " div_3="+" input2="" div_4="148" value={payload?.refund_bal_owing_009} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="line_44800" title="" subTitle="CPP or QPP overpayment (see line 30800 of the guide)" input1="yes" box="44800" div_3_a=" " div_3="+" input2="" div_4="149" value1={payload?.line_44800?.value} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="line_45000" title="" subTitle="Employment insurance (EI) overpayment (see line 45000 of the guide)" input1="yes" box="45000" div_3_a=" " div_3="+" input2="" div_4="150" value1={payload?.line_45000?.value} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="refund_bal_owing_012" title="" subTitle="Refundable medical expense supplement (use Federal Worksheet)" input1="yes" box="45200" div_3_a=" " div_3="+" input2="" div_4="151" value={payload?.refund_bal_owing_012} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="refund_bal_owing_013" title="" subTitle="Canada workers benefit (CWB) (complete Schedule 6) " input1="yes" box="45300" div_3_a=" " div_3="+" input2="" div_4="152" value={payload?.refund_bal_owing_013} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="refund_bal_owing_014" title="" subTitle="Canada training credit (CTC) (complete Schedule 11)" input1="yes" box="45350" div_3_a=" " div_3="+" input2="" div_4="153" value={payload?.refund_bal_owing_014} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="refund_bal_owing_015" title="" subTitle="Refund of investment tax credit (complete Form T2038(IND))" input1="yes" box="45400" div_3_a=" " div_3="+" input2="" div_4="154" value={payload?.refund_bal_owing_015} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="refund_bal_owing_016" title="" subTitle="Part XII.2 tax credit (box 38 of all T3 slips and box 209 of all T5013 slips) " input1="yes" box="45600" div_3_a=" " div_3="+" input2="" div_4="155" value={payload?.refund_bal_owing_016} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="refund_bal_owing_017" title="" subTitle="Employee and partner GST/HST rebate (complete Form GST370)" input1="yes" box="45700" div_3_a=" " div_3="+" input2="" div_4="156" value={payload?.refund_bal_owing_017} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <p>Eligible educator school supply tax credit</p>
                                    <Form_UI_a name="refund_bal_owing_018" title="" subTitle="Supplies expenses (maximum $1,000)" input="yes" box0="46800" input1="25% =" box2="46900" div_3_a="+" input2="yes" div_3="x" div_4="157" value={payload?.refund_bal_owing_018} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="refund_bal_owing_019" title="" subTitle="Canadian journalism labour tax credit (box 236 of all T5013 slips)" input1="yes" box="47555" div_3_a=" " div_3="+" input2="" div_4="158" value={payload?.refund_bal_owing_019} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="refund_bal_owing_020" title="" subTitle="Return of fuel charge proceeds to farmers tax credit (complete Form T2043)" input1="yes" box="47556" div_3_a=" " div_3="+" input2="" div_4="159" value={payload?.refund_bal_owing_020} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="refund_bal_owing_021" title="" subTitle="Air quality improvement tax credit (complete Form T2039) " input1="yes" box="47557" div_3_a=" " div_3="+" input2="" div_4="160" value={payload?.refund_bal_owing_021} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="refund_bal_owing_022" title="" subTitle="Tax paid by instalments" input1="yes" box="47600" div_3_a=" " div_3="+" input2="" div_4="161" value={payload?.refund_bal_owing_022} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="refund_bal_owing_023" title="" subTitle="Provincial or territorial credits (complete Form 479, if it applies)" input1="yes" box="47900" div_3_a=" " div_3="+" input2="" div_4="162" value={payload?.refund_bal_owing_023} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_a name="refund_bal_owing_024" title="" subTitle="Add lines 147 to 162." input="Total credits" input1="yes" box="48200" div_3_a="> -" div_3="=" input2="yes" div_4="163" value2={payload?.line_48200?.value} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    
                                    <p>Line 146 minus line 163</p>
                                    <p>If the amount is negative, enter it on line 48400 below. </p>
                                    <Form_UI_a name="box_164" title="" subTitle="If the amount is positive, enter it on line 48500 below." input1="Refund or balance owing" box="" div_3_a=" " div_3=" " input2="yes" div_4="164" value2={payload?.box_164?.value} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>

                                </MaterialContainer>
                                <br />
                                <br />
                                <MaterialContainer>
                                  <div className="flex gap-2">
                                    <Form_UI_d name="line_48400" title="" subTitle="Refund" input="" input1="" box2="48400" div_3_a=" " div_3="" input2="yes" div_4="" value2={payload?.line_48400?.value} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                    <Form_UI_d name="line_48500" title="" subTitle="Balance owing" input="" input1="" box2="48500" div_3_a=" " div_3="" input2="yes" div_4="" value2={payload?.line_48500?.value} hint='' handleChange={(name, value) => {handleChange(name, value)}}/>
                                  </div>
                                    
                                </MaterialContainer>
                            </div>
{/*                             
                            <div className="form-group-div flex flex-centered error-div">{submitError}</div>
                            <div className='form-group-div flex flex-centered btn-div'>
                                <Button title='submit'/>
                            </div> */}
                            
                        </div>
                    </form>
                </div>
            </div>
            </LayoutSteps>

}

export default F_T5006_AddNew;




const hardValues=()=>{
    return {
        line_10100: {
          value: 200000,
          name: "Employment income (box 14 of all T4 slips)"
        },
        line_10120: {
          value: 0,
          name: "Commissions included on line 10100 (box 42 of all T4 slips)"
        },
        line_20600: {
          value: 222,
          name: "Pension adjustment (box 52 of all T4 slips and box 034 of all T4A slips)"
        },
        line_20700: {
          value: 202,
          name: "Registered pension plan (RPP) deduction (box 20 of all T4 slips and box 032 of all T4A slips)"
        },
        line_21200: {
          value: 22,
          name: "Annual union, professional, or like dues (receipts and box 44 of all T4 slips)"
        },
        line_24400: {
          value: 24,
          name: "Canadian Armed Forces personnel and police deduction (box 43 of all T4 slips)"
        },
        line_24900: {
          value: 442,
          name: "Security options deductions (boxes 39 and 41 of T4 slips or see Form T1212)"
        },
        line_43700: {
          value: 800,
          name: "Total income tax deducted (amounts from all Canadian slips)"
        },
        line_30000: {
          value: 14398,
          name: "Not avilable"
        },
        line_30800: {
          value: 0,
          name: "Not avilable"
        },
        line_22215: {
          value: 0,
          name: "Not avilable"
        },
        line_44800: {
          value: 2000,
          name: "Not avilable"
        },
        line_31200: {
          value: 316,
          name: "Employment insurance premiums: through employment (boxes 18 and 55 of all T4 slips) (maximum $952.74)"
        },
        line_45000: {
          value: 1506,
          name: "Not avilable"
        },
        box_19: {
          value: 201160.59999999998,
          name: "Not avilable"
        },
        box_25: {
          value: 0,
          name: "Not avilable"
        },
        box_26: {
          value: 201160.59999999998,
          name: "Not avilable"
        },
        line_14700: {
          value: 0,
          name: "Not avilable"
        },
        line_15000: {
          value: 201160.59999999998,
          name: "Not avilable"
        },
        box_32: {
          value: 201160.59999999998,
          name: "Not avilable"
        },
        line_23300: {
          value: 446,
          name: "Not avilable"
        },
        line_23400: {
          value: 200714.6,
          name: "Not avilable"
        },
        line_23500: {
          value: 0,
          name: "Not avilable"
        },
        line_23600: {
          value: 200714.6,
          name: "Not avilable"
        },
        box_55: {
          value: 200714.6,
          name: "Not avilable"
        },
        line_25000: {
          value: 0,
          name: "Not avilable"
        },
        line_25700: {
          value: 466,
          name: "Not avilable"
        },
        line_26000: {
          value: 200248.6,
          name: "Not avilable"
        },
        column: {
          value: "D",
          name: "Not avilable"
        },
        box_67: {
          value: 200248.6,
          name: "Not avilable"
        },
        box_68: {
          value: 155625,
          name: "Not avilable"
        },
        box_69: {
          value: 44623.600000000006,
          name: "Not avilable"
        },
        box_70: {
          value: "29%",
          name: "Not avilable"
        },
        box_71: {
          value: 12940.84,
          name: "Not avilable"
        },
        box_72: {
          value: 32180.11,
          name: "Not avilable"
        },
        box_73: {
          value: 45120.95,
          name: "Not avilable"
        },
        box_116: {
          value: 45120.95,
          name: "Not avilable"
        },
        box_81: {
          value: 14398,
          name: "Not avilable"
        },
        box_82: {
          value: 14398,
          name: "Not avilable"
        },
        box_94: {
          value: 1603,
          name: "Not avilable"
        },
        box_96: {
          value: 16001,
          name: "Not avilable"
        },
        box_99: {
          value: 16001,
          name: "Not avilable"
        },
        box_104: {
          value: 16001,
          name: "Not avilable"
        },
        box_106: {
          value: 6021.438,
          name: "Not avilable"
        },
        box_107: {
          value: 2479,
          name: "Not avilable"
        },
        line_33500: {
          value: 16001,
          name: "Not avilable"
        },
        box_112: {
          value: "15%",
          name: "Not avilable"
        },
        line_33800: {
          value: 2400.15,
          name: "Not avilable"
        },
        line_35000: {
          value: 2400.15,
          name: "Not avilable"
        },
        line_40400: {
          value: 45120.95,
          name: "Not avilable"
        },
        box_119: {
          value: 2400.15,
          name: "Not avilable"
        },
        box_122: {
          value: 2402.4300000000003,
          name: "Not avilable"
        },
        line_42900: {
          value: 42718.52,
          name: "Not avilable"
        },
        box_125: {
          value: 42718.52,
          name: "Not avilable"
        },
        box_127: {
          value: 42718.52,
          name: "Not avilable"
        },
        box_129: {
          value: 42718.52,
          name: "Not avilable"
        },
        line_40600: {
          value: 42718.52,
          name: "Not avilable"
        },
        line_41700: {
          value: 42718.52,
          name: "Not avilable"
        },
        line_42000: {
          value: 42718.52,
          name: "Not avilable"
        },
        box_140: {
          value: 42718.52,
          name: "Not avilable"
        },
        line_42200: {
          value: 0,
          name: "Not avilable"
        },
        line_43500: {
          value: 42718.52,
          name: "Not avilable"
        },
        box_146: {
          value: 42718.52,
          name: "Not avilable"
        },
        line_48200: {
          value: 4306,
          name: "Not avilable"
        },
        line_48500: {
          value: 38412.52,
          name: "Not avilable"
        },
        box_164: {
          value: 38412.52,
          name: "Not avilable"
        },
        line_31260: {
          value: 1287,
          name: "Not avilable"
        },
        line_12000: {
          value: 25.3,
          name: "Not avilable"
        },
        line_12010: {
          value: 25.3,
          name: "Not avilable"
        },
        line_12100: {
          value: 1110,
          name: "Not avilable"
        },
        line_40425: {
          value: 2.28,
          name: "Not avilable"
        }
      }
}